import { Link, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { SpinnerCircularFixed } from "spinners-react";
import { handleErrorResponse } from "../../app/apiSlice";
import ButtonPrimary from "../../components/ButtonPrimary";
import ButtonSpinner from "../../components/ButtonSpinner";
import FormPageWrapper from "../../components/FormPageWrapper";
import Layout from "../../components/Layout";
import { SubscriptionPaddleApiPreviewUpdate } from "../../types";
import { formatDateOnly, getPriceLocalized } from "../../utils";
import {
  usePaddleApiPreviewUpdateQuery,
  usePaddleApiSubscriptionUpdatePlanMutation,
  useSubscriptionQuery,
} from "./subscriptionApiSlice";
import * as Sentry from "@sentry/gatsby";

interface SwitchToAnnualProps {
  subscriptionId: number;
}

const SwitchToAnnual = ({ subscriptionId }: SwitchToAnnualProps) => {
  const { data, isFetching: isFetchingPreview } =
    usePaddleApiPreviewUpdateQuery({
      subscriptionId,
      planId: process.env.GATSBY_PRODUCT_ID_ANNUAL,
    });

  const [updateTrigger, { isLoading, isSuccess }] =
    usePaddleApiSubscriptionUpdatePlanMutation();

  const [isAnnualBillingType, setIsAnnualBillingType] = useState(false);
  const { data: subscription, isFetching: isFetchingSubscription } =
    useSubscriptionQuery(subscriptionId, {
      skip: isSuccess ? isAnnualBillingType : true,
      pollingInterval: 1000,
    });

  useEffect(() => {
    if (
      subscription &&
      subscription.bundle === "paddle" &&
      subscription.field_billing_type === "year"
    ) {
      setIsAnnualBillingType(true);
      toast.success("Your plan has been successfully updated!");
      navigate("/user/settings");
    }
  }, [isFetchingSubscription]);

  const handleUpdate = () => {
    updateTrigger({
      subscriptionId,
      planId: process.env.GATSBY_PRODUCT_ID_ANNUAL,
    })
      .unwrap()
      .catch((error) => {
        console.error(error);
        handleErrorResponse(error).forEach((m) => {
          const e = Error(m);
          e.name = "SwitchToAnnual: handleUpdate";
          Sentry.captureException(e);
        });
      });
  };

  useEffect(() => {
    if (data?.error) {
      console.log(data.error);
      handleErrorResponse(data.error);
      navigate("/user/settings");
    }
  }, [isFetchingPreview]);

  if (!data || isFetchingPreview) {
    return (
      <Layout>
        <FormPageWrapper title="Switch to Annual">
          <div className="flex justify-center w-full">
            <SpinnerCircularFixed
              className="inline-block"
              size="40"
              color="#D3D3D3"
              secondaryColor="rgb(75, 85, 99)"
            />
          </div>
        </FormPageWrapper>
      </Layout>
    );
  }
  if (data.success) {
    const preview: SubscriptionPaddleApiPreviewUpdate = data.response;

    return (
      <Layout>
        <FormPageWrapper title="Switch to Annual">
          <div className="text-neutral-700">
            You will be charged{" "}
            <span className="font-bold">
              {getPriceLocalized(
                preview.immediate_payment.currency,
                preview.immediate_payment.amount
              )}
            </span>{" "}
            today and then
            {` ${getPriceLocalized(
              preview.next_payment.currency,
              preview.next_payment.amount
            )} `}
            annually, including tax, starting on
            {` ${formatDateOnly(preview.next_payment.date)} `}
            unless automatic billing is canceled.
            <div className="mt-6 flex items-center">
              <ButtonPrimary
                size="20"
                onClick={handleUpdate}
                disabled={isLoading || (isSuccess && !isAnnualBillingType)}
              >
                Confirm
                <ButtonSpinner
                  enabled={isLoading || (isSuccess && !isAnnualBillingType)}
                />
              </ButtonPrimary>
              <Link
                className="ml-10 text-red-700 hover:test-red-600 underline"
                to="/user/settings"
              >
                Cancel
              </Link>
            </div>
          </div>
        </FormPageWrapper>
      </Layout>
    );
  }
  return null;
};

export default SwitchToAnnual;
