import React from "react";

interface ButtonPrimaryProps {
  size: "10" | "20";
  children: React.ReactNode;
  type?: "submit" | "button" | "reset";
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ButtonPrimary = ({
  size,
  children,
  type = "submit",
  disabled = false,
  onClick,
}: ButtonPrimaryProps) => {
  let classes = `w-full sm:w-auto bg-blue-700 text-white rounded-lg focus:bg-gradient-to-r hover:bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700
    active:bg-blue-800`;
  switch (size) {
    case "10":
      classes += " px-4 py-2";
      break;
    case "20":
      classes += " px-6 py-3 text-xl";
      break;
  }
  return (
    <button
      className={classes}
      type={type}
      disabled={disabled}
      {...(typeof onClick === "function" ? { onClick: onClick } : {})}
    >
      {children}
    </button>
  );
};

export default ButtonPrimary;
