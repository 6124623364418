import { skipToken } from "@reduxjs/toolkit/dist/query";
import { navigate } from "gatsby";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useCurrentUserQuery } from "./userApiSlice";

export const useRequireAuthentication = (to = "/login"): void => {
  const { data: currentUser, isSuccess } = useCurrentUserQuery();

  useEffect(() => {
    if (isSuccess && !currentUser?.is_authenticated) {
      toast.error("You must be logged in to view this page.");
      console.log("You must be logged in to view this page");
      navigate(to);
    }
  }, [isSuccess]);
};
