import React from "react";
import Back from "./Back";

interface FormPageWrapper {
  backUrl?: string;
  title?: string;
  children: React.ReactNode;
}

const FormPageWrapper = ({ backUrl, title, children }: FormPageWrapper) => {
  return (
    <div className="py-6 md:py-16 md:px-6">
      <div
        className="bg-white px-6 py-10 sm:p-10 xl:px-40 xl:pb-16 rounded-lg ring-1 ring-amber-700 ring-offset-0 
      sm:w-11/12 xl:w-3/4 mx-auto"
      >
        {backUrl && <Back to={backUrl} />}
        {title && (
          <h1 className="mb-8 text-5xl font-bold text-neutral-700 text-center">
            {title}
          </h1>
        )}
        {children}
      </div>
    </div>
  );
};

export default FormPageWrapper;
