import React from "react";
import { SpinnerCircularFixed } from "spinners-react";

interface ButtonSpinnerProps {
  enabled: boolean;
}
const ButtonSpinner = ({ enabled }: ButtonSpinnerProps) => {
  return (
    <SpinnerCircularFixed
      className="inline-block ml-2"
      size="15"
      color="#D3D3D3"
      secondaryColor="rgb(200, 200, 200)"
      enabled={enabled}
    />
  );
};

export default ButtonSpinner;
