import React from "react";
import Navbar from "../features/navbar/Navbar";
import Footer from "../features/footer/Footer";

interface LayoutProps {
  isCheckout?: boolean;
  children: React.ReactNode;
}

const Layout = ({ isCheckout = false, children }: LayoutProps) => {
  return (
    <>
      <Navbar isCheckout={isCheckout} />
      <div className="bg-neutral-100 min-h-screen grid grid-cols-1 justify-items-center px-4 pb-8">
        <div className="container">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
