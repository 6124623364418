import { Link } from "gatsby";
import React from "react";
import { MdOutlineArrowBack } from "react-icons/md";

interface BackProps {
  to: string;
}

const Back = ({ to }: BackProps) => {
  return (
    <Link
      className="bg-neutral-100 inline-block rounded-2xl p-1"
      title="Back"
      to={to}
    >
      <MdOutlineArrowBack />
    </Link>
  );
};

export default Back;
