import { HeadProps, PageProps } from "gatsby";
import React from "react";
import Seo from "../../../features/seo/Seo";
import SwitchToAnnual from "../../../features/subscription/SwitchToAnnual";
import { useRequireAuthentication } from "../../../features/user/hooks";

const SwitchToAnnualPage = (props: PageProps) => {
  useRequireAuthentication();

  return <SwitchToAnnual subscriptionId={parseInt(props.params.id)} />;
};

export default SwitchToAnnualPage;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Switch to annual | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
